import jwtDecode from "jwt-decode";
import { checkAndRefreshToken } from "./authUtils";
import CryptoJS from "crypto-js";
import { SHA256, enc } from "crypto-js";
// Get user info by using the token available in localstorage.

export function getUserIdFromToken(token) {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.data.user.id;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
}

export const getUserNames = async () => {
  const token = await checkAndRefreshToken();
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

export const computeSignature = (
  secretKey,
  method,
  url,
  queryParams,
  formData,
  body,
  fileContent
) => {
  const messageParts = [method, url];

  if (Object.keys(queryParams).length > 0) {
    const sortedQueryParams = Object.keys(queryParams)
      .sort()
      .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join("&");

    messageParts.push(sortedQueryParams);
  }

  if (Object.keys(formData).length > 0) {
    const sortedFormData = Object.entries(formData)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    messageParts.push(sortedFormData);
  }

  if (body) {
    const bodyHash = SHA256(enc.Utf8.parse(body)).toString();
    messageParts.push(bodyHash);
  }

  if (fileContent) {
    const fileContentHash = SHA256(enc.Utf8.parse(fileContent)).toString();
    messageParts.push(fileContentHash);
  }

  const message = messageParts.join("\n");

  const signature = CryptoJS.HmacSHA256(message, secretKey);

  return CryptoJS.enc.Base64.stringify(signature)
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};
