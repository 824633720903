/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import SavedQuestionsSidebar from "./savedQuestionsSidebar";
import SavedQuestionsContainer from "./savedQuestionsContainer";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import {
  addMessageToSession,
  bookmarkQuestions,
  getSavedQuestions,
} from "../../../apis/recommendation/recommendation";
import { DataContext } from "../../../components/layout/layout";
import { CircularProgress, Drawer, TextField } from "@mui/material";
import { devApis } from "../../../constants/constant";
import Cookies from "js-cookie";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const SavedQuestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredRec, setFilteredRec] = useState([]);
  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);
  const [isPromptEdited, setIsPromptEdited] = useState(false);
  const [editedPromptData, setEditedPromptData] = useState(null);
  const [savedQuestionsData, setSavedQuestionsData] = useState(null);
  const [addingNewQuestion, setAddingNewQuestion] = useState(false);

  const {
    setHighlightField,
    filesListData,
    updatedQuestionsData,
    setUpdatedQuestionsData,
  } = useContext(DataContext);

  const navigate = useNavigate();

  const savedQuestionsAPIFunction = async () => {
    const { saved_messages } = await getSavedQuestions();
    setFilteredRec(saved_messages);
    setSelectedSuggestion(saved_messages[0]);
    setUpdatedQuestionsData(null);
  };

  useEffect(() => {
    savedQuestionsAPIFunction();

    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("History");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [updatedQuestionsData]);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    setHighlightField("Ask Anything");
    navigate("/");
  };

  const playGroundTopBar = () => {
    return (
      <div className="flex items-center shadow-md p-3 md:p-5 relative z-10">
        <Button
          className="soi-btn-fab btn-outline-dark me-2 size-8 flex lg:hidden"
          size={"xs"}
          onClick={handleModileSidebarToggle}
        >
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 12V10H11V12H7ZM3 7V5H15V7H3ZM0 2V0H18V2H0Z"
              fill="#111111"
              fillOpacity="0.74"
            />
          </svg>
        </Button>
        <h1 className="font-normal text-lg md:text-xl text-primary-dark">
          Saved Questions
        </h1>
        <div className="items-center ms-auto flex gap-5">
          <Button
            variant="default"
            className="hidden lg:flex"
            size={"sm"}
            onClick={toggleAddNewQuationDrawer(true)}
          >
            Add New
          </Button>
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}
          >
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  const [openAddNewQuationDrawer, setAddNewQuationDrawerOpen] =
    React.useState(false);
  const toggleAddNewQuationDrawer = (newOpen) => () => {
    setAddNewQuationDrawerOpen(newOpen);
  };

  const [customPrompt, setCustomPrompt] = useState("");

  const handleInput = (event) => {
    const { value } = event.target;
    setCustomPrompt(value);
  };

  const debouncedInputHandler = debounce(handleInput, 500);

  // if (!selectedSuggestion) return;

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className={`h-full w-full flex flex-col overflow-hidden`}>
        {playGroundTopBar()}
        <div className={`flex flex-row overflow-hidden h-full`}>
          <SavedQuestionsSidebar
            recommendationData={filteredRec}
            setSelectedSuggestion={setSelectedSuggestion}
            setIsPromptEdited={setIsPromptEdited}
            selectedSuggestion={selectedSuggestion}
            setEditedPromptData={setEditedPromptData}
            setSavedQuestionsData={setSavedQuestionsData}
            setModileSidebarIsActive={setModileSidebarIsActive}
            isModileSidebarActive={isModileSidebarActive}
          />
          <SavedQuestionsContainer
            recommendationData={selectedSuggestion}
            isPromptEdited={isPromptEdited}
            editedPromptData={editedPromptData}
            savedQuestionsData={savedQuestionsData}
            setSavedQuestionsData={setSavedQuestionsData}
          />
        </div>
      </div>
      <Drawer
        anchor={"right"}
        open={openAddNewQuationDrawer}
        onClose={toggleAddNewQuationDrawer(false)}
      >
        <div className="sio-sidebar-right w-[300px] md:w-[500px]">
          <div className="sio-sidebar-header flex items-center">
            <h2>Add New Question</h2>
            <Button
              className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
              onClick={toggleAddNewQuationDrawer(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.54">
                  <path
                    d="M12 13.3288L5.60303 19.7261C5.42838 19.9005 5.20886 19.9898 4.94448 19.994C4.6803 19.998 4.45679 19.9087 4.27395 19.7261C4.09132 19.5432 4 19.3217 4 19.0615C4 18.8013 4.09132 18.5798 4.27395 18.397L10.6712 12L4.27395 5.60303C4.09951 5.42838 4.01019 5.20886 4.00599 4.94448C4.002 4.6803 4.09132 4.45679 4.27395 4.27395C4.45679 4.09132 4.6783 4 4.93849 4C5.19867 4 5.42018 4.09132 5.60303 4.27395L12 10.6712L18.397 4.27395C18.5716 4.09951 18.7911 4.01019 19.0555 4.00599C19.3197 4.002 19.5432 4.09132 19.7261 4.27395C19.9087 4.45679 20 4.67831 20 4.93849C20 5.19867 19.9087 5.42018 19.7261 5.60303L13.3288 12L19.7261 18.397C19.9005 18.5716 19.9898 18.7911 19.994 19.0555C19.998 19.3197 19.9087 19.5432 19.7261 19.7261C19.5432 19.9087 19.3217 20 19.0615 20C18.8013 20 18.5798 19.9087 18.397 19.7261L12 13.3288Z"
                    fill="#111111"
                    fillOpacity="0.74"
                  />
                </g>
              </svg>
            </Button>
          </div>

          {addingNewQuestion ? (
            <div
              className="flex flex-col justify-start items-center gap-10 text-md 
            font-semibold w-full h-full pt-20"
            >
              <svg width={0} height={0}>
                <defs>
                  <linearGradient
                    id="my_gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress
                sx={{
                  "svg circle": { stroke: "url(#my_gradient)" },
                  size: "20px",
                }}
              />
              <h3>Please wait while we adding the question.</h3>
            </div>
          ) : (
            <>
              <div className="sio-sidebar-body h-auto p-6 !py-7">
                <div className="grid grid-cols-1 gap-y-6">
                  <TextField
                    label="Question"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={debouncedInputHandler}
                  />
                </div>
              </div>

              <div className="sio-sidebar-footer gap-4 flex pt-0">
                <Button
                  variant="secondary"
                  size={"sm"}
                  onClick={async () => {
                    if (customPrompt) {
                      setAddingNewQuestion(true);
                      const promptResponse = await fetch(
                        `${devApis.PYTHON_BASE_URL}/start_conversation`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("token")}`,
                          },
                          body: JSON.stringify({
                            recommendation_id: 0,
                          }),
                        }
                      );

                      const data = await promptResponse.json();
                      const newSessionId = data.session_id;

                      const token = await checkAndRefreshToken();
                      const response = await addMessageToSession(
                        newSessionId,
                        customPrompt,
                        token,
                        filesListData
                      );

                      await bookmarkQuestions(response?.message_id);
                      savedQuestionsAPIFunction();
                      setAddingNewQuestion(false);
                      setAddNewQuationDrawerOpen(false);
                      setUpdatedQuestionsData(true);
                      toast.success("Question added successfully");
                    } else {
                      toast("Please provide the custom question");
                    }
                  }}
                >
                  {" "}
                  Save Question{" "}
                </Button>
                <Button
                  variant="default"
                  onClick={toggleAddNewQuationDrawer(false)}
                  size={"sm"}
                  className="text-black"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SavedQuestions;
