/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import FilesUploaded from "./files-uploaded/files-uploaded";
import QuestionPrompts from "./question-prompts/question-prompts";
import DataSourceFiles from "./teacher/files/dataSourceFiles";
import {
  getFilesList,
  getQuestionPromptsData,
} from "../../apis/recommendation/recommendation";
import Cookies from "js-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import { DataContext } from "../../components/layout/layout";
const DataSource = () => {
  const { isArchivedDataSources, setIsArchivedDataSources } =
    useContext(DataContext);

  const [dataSource, setDataSource] = useState(null);
  const [questionPrompts, setQuestionPrompts] = useState(null);
  const adminRoles = ["site-admin", "district-admin"];

  const getFileNames = async () => {
    const files = await getFilesList();
    const filteredFileData = files.filter(
      (file) =>
        file.original_file_name.includes(".csv") ||
        file.original_file_name.includes(".json")
    );
    setDataSource(filteredFileData);
  };

  const getQuestionPrompts = async () => {
    const data = await getQuestionPromptsData();
    setQuestionPrompts(data);
  };

  useEffect(() => {
    getFileNames();
    getQuestionPrompts();
  }, []);

  const tabs = [
    {
      name: "Files Uploaded",
    },
    {
      name: "Question Prompts",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const [isTeacherView, setIsTeacherView] = useState(false);

  useEffect(() => {
    const checkRole = () => {
      const userRole = Cookies.get("user_role") || "teacher";
      if (adminRoles.includes(userRole?.toLowerCase())) {
        setIsTeacherView(true);
      } else {
        setIsTeacherView(false);
      }
    };
    checkRole();
  }, []);

  const s3KeysData =
    dataSource &&
    dataSource.map((item) => ({
      name: item.s3_key,
      date: item.created_at,
    }));

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <div className="bg-white h-full rounded-lg justify-center">
      <div className="flex flex-col h-full">
        <div className="items-start md:items-center flex shadow-md p-3 !pb-0 md:p-5 relative z-10">
          <div>
            {isArchivedDataSources ? (
              <h1 className="font-normal text-lg md:text-xl text-primary-dark flex gap-3 items-center mb-4">
                <button
                  className="soi-btn soi-btn-fab soi-btn-flat btn-dark size-9"
                  onClick={() => {
                    setIsArchivedDataSources(false);
                    handleBack();
                  }}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.43333 12.3332L11.9667 18.8666C12.2333 19.1332 12.3611 19.4443 12.35 19.7999C12.3389 20.1555 12.2 20.4666 11.9333 20.7332C11.6667 20.9777 11.3555 21.1055 11 21.1166C10.6444 21.1277 10.3333 20.9999 10.0667 20.7332L1.26666 11.9332C1.13333 11.7999 1.03888 11.6555 0.983327 11.4999C0.927772 11.3443 0.899994 11.1777 0.899994 10.9999C0.899994 10.8221 0.927772 10.6555 0.983327 10.4999C1.03888 10.3443 1.13333 10.1999 1.26666 10.0666L10.0667 1.26657C10.3111 1.02212 10.6167 0.899902 10.9833 0.899902C11.35 0.899902 11.6667 1.02212 11.9333 1.26657C12.2 1.53324 12.3333 1.8499 12.3333 2.21657C12.3333 2.58324 12.2 2.8999 11.9333 3.16657L5.43333 9.66657H20.3333C20.7111 9.66657 21.0278 9.79435 21.2833 10.0499C21.5389 10.3055 21.6667 10.6221 21.6667 10.9999C21.6667 11.3777 21.5389 11.6943 21.2833 11.9499C21.0278 12.2055 20.7111 12.3332 20.3333 12.3332H5.43333Z"
                      fill="#111111"
                      fill-opacity="0.74"
                    />
                  </svg>
                </button>{" "}
                Archived Data Source
              </h1>
            ) : (
              <h1 className="font-normal text-lg md:text-xl text-primary-dark">
                Data Source
              </h1>
            )}

            {!isTeacherView && (
              <p className="text-secondary-dark text-xs mt-1 md:mt-0 md:text-sm pb-4">
                You can check/unchek the data sources to get customized
                suggestions.
              </p>
            )}
            {isTeacherView && !isArchivedDataSources && (
              <div className="sio-tabs -mx-3 mt-3">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`sio-tab-link ${
                      activeTab === tab.name
                        ? "active relative text-primary-dark before:absolute before:h-[3px] before:rounded-full before:bg-primary before:w-14 before:bottom-0"
                        : "text-muted-dark relative"
                    }`}
                    onClick={() => handleTabClick(tab.name)}>
                    {tab.name}
                    {tab.new === true && (
                      <span className="bg-warning size-[6px] absolute rounded-full top-3"></span>
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        {isArchivedDataSources ? (
          <Outlet />
        ) : !isTeacherView ? (
          <DataSourceFiles dataSource={dataSource} />
        ) : (
          <>
            {activeTab === "Files Uploaded" && (
              <>
                {!isArchivedDataSources && (
                  <FilesUploaded dataSource={dataSource} />
                )}
              </>
            )}
            {activeTab === "Question Prompts" && (
              <QuestionPrompts
                s3KeysData={s3KeysData}
                questionPrompts={questionPrompts}
                setQuestionPrompts={setQuestionPrompts}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DataSource;
