/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { devApis } from "../../../constants/constant";
import { Box, CircularProgress } from "@mui/material";
import Cookies from "js-cookie";

export default function FileUploadComponent() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOrder, setIsOrder] = useState(false);
  const [fileResponse, setFileResponse] = useState(true);
  const [filesData, setFilesData] = useState([]);

  useEffect(() => {
    getFilesUploadData();
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleCheckBox = () => {
    setIsOrder(() => !isOrder);
  };

  const generateFileUploadURL = async () => {
    setFileResponse(false);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("should_reorder_columns", isOrder);
    const reponose = await fetch(`${devApis.PYTHON_BASE_URL}/anonymize_csv`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      body: formData,
    });
    const data = await reponose.json();
    setFilesData((prevData) => {
      const newData = {
        expiry_datetime: data.expiry_datetime,
        presigned_url: data.anonymized_csv_url,
      };
      return [newData, ...prevData];
    });
    setFileResponse(true);
    setSelectedFile(null);
  };

  const getFilesUploadData = async () => {
    const reponose = await fetch(
      `${devApis.PYTHON_BASE_URL}/list_anonymized_csvs`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    const data = await reponose.json();
    setFilesData(data.anonymized_files);
  };

  const handleUpload = () => {
    console.log(selectedFile);
    generateFileUploadURL();
  };

  function formatDate(date) {
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let day = String(date.getDate()).padStart(2, "0");
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");

    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
  }

  if (filesData.length < 0) return;

  return (
    <div className="bg-white rounded-md min-h-full flex flex-col justify-around items-center">
      {fileResponse ? (
        <div className="w-1/3 flex flex-col items-center">
          <input
            type="file"
            id="fileUpload"
            className="hidden"
            onChange={handleFileChange}
          />
          <label
            htmlFor="fileUpload"
            className="border-2 border-gray-400 border-dashed h-40 w-full 
          flex justify-center items-center text-center cursor-pointer rounded-md"
            onDrop={handleDrop}
            onDragOver={handleDragOver}>
            {selectedFile ? (
              <span className="font-semibold w-[90%] overflow-clip whitespace-nowrap text-ellipsis">
                {selectedFile.name}
              </span>
            ) : (
              "Click to upload or drag and drop file here"
            )}
          </label>
          <div className="flex items-center p-5">
            <input
              type="checkbox"
              id="agreeTerms"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 
            rounded hover:cursor-pointer"
              onChange={handleCheckBox}
            />
            <label className="ms-4">Reorder Columns</label>
          </div>
          <button
            className="border-none px-4 py-2 rounded-lg bg-blue-400 hover:bg-blue-500 
        text-white transition ease-in duration-200 transform hover:scale-105"
            onClick={handleUpload}>
            Upload
          </button>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      )}

      {(filesData.length === 1 || filesData.length >= 1) &&
      new Date().getTime() - new Date(filesData[0].expiry_datetime).getTime() <
        0 ? (
        <div className="max-h-96 overflow-y-auto w-[90%] border-2 border-gray-300">
          <table className="border-collapse w-full">
            <thead className="bg-gray-100 sticky -top-1 z-10">
              <tr>
                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">
                  S.No
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">
                  Anonymized csv url
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left bg-gray-100">
                  Expiry Datetime
                </th>
              </tr>
            </thead>
            <tbody>
              {filesData.map((row, ind) => {
                if (
                  new Date().getTime() -
                    new Date(row.expiry_datetime).getTime() >
                  0
                ) {
                  return null;
                }
                return (
                  <tr key={ind}>
                    <td className="border border-gray-300 px-4 py-2">
                      {ind + 1}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 w-[75%]">
                      <a
                        href={row.presigned_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline">
                        {row.presigned_url}
                      </a>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {formatDate(new Date(row.expiry_datetime))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}
