// theme.js
import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

const CustomRadioIconChecked = () => (
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 9C0 4.02944 4.02944 0 9 0V0C13.9706 0 18 4.02944 18 9V9C18 13.9706 13.9706 18 9 18V18C4.02944 18 0 13.9706 0 9V9Z" fill="#00CC8F"/>
<g filter="url(#filter0_d_0_1)">
<path d="M5 9C5 6.79086 6.79086 5 9 5V5C11.2091 5 13 6.79086 13 9V9C13 11.2091 11.2091 13 9 13V13C6.79086 13 5 11.2091 5 9V9Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_0_1" x="1" y="5" width="16" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
</filter>
</defs>
</svg>
);

const CheckIconDark = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z"
      fill="white"
    />
    <path
      opacity="0.2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V14C1.5 15.3807 2.61929 16.5 4 16.5H14C15.3807 16.5 16.5 15.3807 16.5 14V4C16.5 2.61929 15.3807 1.5 14 1.5ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z"
      fill="black"
    />
  </svg>
);

const CheckedIconDark = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4Z"
      fill="#00CC8F"
    />
    <path
      d="M7.99217 12.8324C7.68746 13.1371 7.19525 13.1371 6.89055 12.8324L4.08571 10.0276C3.781 9.72288 3.781 9.23067 4.08571 8.92596C4.39041 8.62126 4.88262 8.62126 5.18733 8.92596L7.43745 11.1761L12.8127 5.80079C13.1174 5.49609 13.6097 5.49609 13.9144 5.80079C14.2191 6.1055 14.2191 6.59771 13.9144 6.90242L7.99217 12.8324Z"
      fill="white"
    />
  </svg>
);

const CustomCheckIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5" filter="url(#filter0_d_151_1680)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H8C6.34315 4 5 5.34315 5 7V17C5 18.6569 6.34315 20 8 20H18C19.6569 20 21 18.6569 21 17V7C21 5.34315 19.6569 4 18 4ZM8 3C5.79086 3 4 4.79086 4 7V17C4 19.2091 5.79086 21 8 21H18C20.2091 21 22 19.2091 22 17V7C22 4.79086 20.2091 3 18 3H8Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_151_1680"
        x="0"
        y="3"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_151_1680"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_151_1680"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const CustomIndeterminateIcon = () => ( 
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V14C1.5 15.3807 2.61929 16.5 4 16.5H14C15.3807 16.5 16.5 15.3807 16.5 14V4C16.5 2.61929 15.3807 1.5 14 1.5ZM4 0C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H14C16.2091 18 18 16.2091 18 14V4C18 1.79086 16.2091 0 14 0H4Z" fill="#777674"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 8C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10H13C13.5523 10 14 9.55228 14 9C14 8.44772 13.5523 8 13 8H5Z" fill="#777674"/>
  </svg> 
);

const CustomCheckedIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M1 6C1 2.68629 3.68629 0 7 0H19C22.3137 0 25 2.68629 25 6V18C25 21.3137 22.3137 24 19 24H7C3.68629 24 1 21.3137 1 18V6Z"
      fill="white"
    />
    <g opacity="0.2" filter="url(#filter0_d_151_1675)">
      <path
        d="M4 7C4 4.79086 5.79086 3 8 3H18C20.2091 3 22 4.79086 22 7V17C22 19.2091 20.2091 21 18 21H8C5.79086 21 4 19.2091 4 17V7Z"
        fill="white"
      />
    </g>
    <path
      d="M11.9922 15.8317C11.6875 16.1364 11.1952 16.1364 10.8905 15.8317L8.08571 13.0269C7.781 12.7221 7.781 12.2299 8.08571 11.9252C8.39041 11.6205 8.88262 11.6205 9.18733 11.9252L11.4374 14.1754L16.8127 8.80006C17.1174 8.49536 17.6097 8.49536 17.9144 8.80006C18.2191 9.10477 18.2191 9.59698 17.9144 9.90168L11.9922 15.8317Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_151_1675"
        x="0"
        y="3"
        width="26"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_151_1675"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_151_1675"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const CustomRadioIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0Z" fill="black"/>
  </svg>
);


// Light Theme
export const lightTheme = createTheme({
  typography: {
    fontFamily: "Manrope, sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(17, 17, 17, 0.12)",
        },
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          borderRadius: 8,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(17, 17, 17, 1)",
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "rgba(17, 17, 17, 0.54)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#CF037E",
          },
          "& input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px #111111 inset",
            "-webkit-text-fill-color": "#111111",
            backgroundColor: "#111111",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(17, 17, 17, 0.54)", // default color
          [`&.${outlinedInputClasses.focused}`]: {
            color: "#CF037E", // color when focused
          },
          [`&:hover`]: {
            color: "rgba(17, 17, 17, 0.87)", // color on hover
          },
          "&.MuiInputLabel-shrink": {
            color: "#CF037E", // Change this color as needed
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: "0 4px 16px -1px rgb(0 0 0 / 0.06)", 
          border: "1px solid rgba(17, 17, 17, 0.12)",
          "&:first-of-type": {
            borderRadius: '8px',
          },
          "&:before": {
            display: "none", 
          },
          "&.Mui-expanded": {
            margin: "0",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          transition: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: '16px 0 0 16px',
          backgroundColor: '#fff',
          boxShadow:'none',
          '@media (max-width:600px)': {
            // Styles for mobile
            width: '90%',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          marginTop: "0", // Added units to marginTop
          minWidth: 160,
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          color: "rgba(17, 17, 17, 0.74)",
          // Override styles for the list
          "& .MuiMenu-list": {
            padding: "8px 0",
          },
          maxHeight: 250,
          // Override styles for individual menu items
          "& .MuiMenuItem-root": {
            fontFamily: "Manrope, sans-serif",
            color: "rgba(17, 17, 17, 0.74)",
            padding: "10px 12px",
            "& .MuiListItemText-root":{
              fontSize: "14px",
              fontWeight:"500",
              "& .MuiListItemText-primary":{
                fontSize: "14px",
                fontWeight:"500",
              }
            },
            "& .soi-icon": {
              fontSize: 18,
              color: "rgba(0, 0, 0, 0.54)",
              marginRight: 12,
              "& svg": {
                fill: "#111111",
              },
            },
            "&:hover": {
              color: "#111111",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            "&:active": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // To ensure any global overrides for the Paper component work
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 8,
          color: "rgba(0, 0, 0, 0.20)", // Default unchecked color
          "&.Mui-checked": {
            color: "#CF037E", // Color when checked
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05) !important", // Hover effect
          },
          "&.Mui-disabled": {
            color: "#757575", // Color when disabled
          },
        },
        // Additional states
        "&.Mui-checked": {
          color: "#353535", // Ensure this is set for checked
          "&.Mui-disabled": {
            color: "#555555", // Color for checked disabled state
          },
        },
        // You can also override the label if needed
        label: {
          color: "#ffffff", // Label color
        },
      },
      defaultProps: {
        icon: <CheckIconDark />, // Custom icon for the checkbox
        checkedIcon: <CheckedIconDark />, // Custom checked icon
        indeterminateIcon: <CustomIndeterminateIcon />
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 8,
        }
      },
      defaultProps: {
        icon: <CustomRadioIcon />, // Custom icon for the checkbox
        checkedIcon: <CustomRadioIconChecked />, // Custom checked icon
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // Customize the backdrop background color and opacity
          // backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {

        },
        paper: {
          borderRadius: '16px',
          backgroundColor: '#fff',
          boxShadow:'none',
          margin:"16px"
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          marginTop: "4px",
          minWidth: 16,
          maxWidth: 280,
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          color: "rgba(17, 17, 17, 0.74)",
        }
      }
    }
  },
});

// Dark Theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#353535", // Customize your primary color for dark mode
    },
    background: {
      default: "#121212", // Background color for dark mode
      paper: "#111111",
    },
  },
  components: {
    typography: {
      fontFamily: "Manrope, sans-serif", // Set your default font family
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(255, 255, 255, 0.16)",
        },
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          borderRadius: 8,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(255, 255, 255, 1)",
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "rgba(255, 255, 255, 0.54)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#CF037E",
          },
          "& input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px #111111 inset",
            "-webkit-text-fill-color": "white",
            backgroundColor: "#111111",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(255, 255, 255, 0.54)", // default color
          [`&.${outlinedInputClasses.focused}`]: {
            color: "#CF037E", // color when focused
          },
          [`&:hover`]: {
            color: "rgba(255, 255, 255, 0.87)", // color on hover
          },
          "&.MuiInputLabel-shrink": {
            color: "#CF037E", // Change this color as needed
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          color: "rgba(255, 255, 255, 0.54)", // Default unchecked color
          "&.Mui-checked": {
            color: "#353535", // Color when checked
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Hover effect
          },
          "&.Mui-disabled": {
            color: "#757575", // Color when disabled
          },
        },
        // Additional states
        "&.Mui-checked": {
          color: "#353535", // Ensure this is set for checked
          "&.Mui-disabled": {
            color: "#555555", // Color for checked disabled state
          },
        },
        // You can also override the label if needed
        label: {
          color: "#ffffff", // Label color
        },
      },
      defaultProps: {
        icon: <CustomCheckIcon />, // Custom icon for the checkbox
        checkedIcon: <CustomCheckedIcon />, // Custom checked icon
        indeterminateIcon:  <CustomIndeterminateIcon />, // Custom checked icon
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          backgroundColor: '#fff',
          marginTop: "0", // Added units to marginTop
          minWidth: 160,
          maxHeight: 200,
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          color: "rgba(17, 17, 17, 0.74)",
          // Override styles for the list
          "& .MuiMenu-list": {
            padding: "8px 0",
          },
          // Override styles for individual menu items
          "& .MuiMenuItem-root": {
            fontSize: "15px",
            fontFamily: "Manrope, sans-serif",
            color: "rgba(17, 17, 17, 0.74)",
            padding: "8px 12px",
            "& .soi-icon": {
              fontSize: 18,
              color: "rgba(0, 0, 0, 0.54)",
              marginRight: 12,
              "& svg": {
                fill: "#111111",
              },
            },
            "&:hover": {
              color: "#111111",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            "&:active": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // Customize root accordion styles here
          boxShadow: "none", // Remove box-shadow
          backgroundImage: "none",
          "&:before": {
            display: "none", // Remove the default divider
          },
          "&.Mui-expanded": {
            margin: "0", // Disable margin change on expansion
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          transition: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          // Disable the transition on expand/collapse icon or summary
          transition: "none",
          minHeight: "32px",
          padding: 0,
          "&.Mui-expanded": {
            // Prevent height from changing
          },
          "& .MuiAccordionSummary-content": {
            margin: "0", // Disable margin changes
            transition: "none",
          },
        },
      },
    },
  },
});
