import Button from "../components/button";
import React, { useEffect, useState } from "react";

const TableContainer = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const rowsPerPage = 5;

  // Check if the screen is mobile
  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 767);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  if (!children) return;
  // console.log(children);
  const updatedChildren = Array.isArray(children) ? children : [children];
  const thead = updatedChildren.find((child) => child.key.startsWith("thead"));
  const tbody = updatedChildren.find((child) => child.key.startsWith("tbody"));
  // console.log(thead, "tbody");
  if (!tbody) return null;

  const theadChildren = Array.isArray(thead.props.children)
   ? thead.props.children
   : [thead.props.children];

   const thTitles = theadChildren
    .find((row) => row.type === "tr")
    ?.props?.children.map((th) =>
      Array.isArray(th.props.children)
        ? th.props.children.join(" ")
        : th.props.children
    );
  
  // const rows = Array.isArray(tbody)
  //   ? tbody
  //   : [tbody]
  //   ? Array.isArray(tbody.props.children)
  //     ? tbody.props.children
  //     : [tbody.props.children]
  //   : [];

  const rows = Array.isArray(tbody.props.children)
    ? tbody.props.children
    : [tbody.props.children];

  const totalPages = Math.ceil(rows.length / rowsPerPage);
  const currentRows = isMobile ? rows : rows.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const processedRows = currentRows.map((row) => {
    if (row.type === "tr" && Array.isArray(row.props.children)) {
      const updatedCells = row.props.children.map((cell, index) => {
        if (cell.type === "td" && thTitles[index]) {
          return React.cloneElement(cell, {
            "data-title": thTitles[index],
          });
        }
        return cell;
      });
      return React.cloneElement(row, {}, updatedCells);
    }
    return row;
  });

  return (
    <div className="sio-table-card-sm sio-card-shadow sio-card-sm !p-0 gap-0">
      <div className={`overflow-x-auto scrollbar-none`}>
          <table className="sio-table">
            {children.filter((child) => !child.key.startsWith("tbody"))}
            <tbody className="">{processedRows}</tbody>
          </table>
      </div>
      {!isMobile && (
      <div className="flex justify-between p-3 gap-2">
        <span className="text-sm text-secondary-dark me-auto">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          variant="dark"
          size="xs"
          onClick={handlePrevPage}
          disabled={currentPage <= 1}
          className={`${
            currentPage <= 1
              ? "cursor-not-allowed btn-default text-gray-400"
              : ""
          }`}
        >
          {" "}
          Previous{" "}
        </Button>
        <Button
          variant="dark"
          size="xs"
          onClick={handleNextPage}
          disabled={currentPage >= totalPages}
          className={`${
            currentPage >= totalPages
              ? "cursor-not-allowed btn-default text-gray-400"
              : ""
          }`}
        >
          Next
        </Button>
      </div>
      )}
    </div>
  );
};

export default TableContainer;
