/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "../components/button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import icon from "../../src/assets/response_icon.png";
import Charts from "./Charts";
import { checkAndRefreshToken } from "../utils/authUtils";
import {
  bookmarkQuestions,
  regenerateResponse,
} from "../apis/recommendation/recommendation";
import { FiDownload, FiShare, FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { saveAs } from "file-saver";
import { HiOutlineRefresh } from "react-icons/hi";
import { debounce } from "lodash";
import { getUserNames } from "../utils";
import { devApis } from "../constants/constant";
import { toast } from "react-toastify";
import TableContainer from "./TableContainer";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const MarkdownComponent = ({
  markDownContainerData,
  loadingPromptResponse,
  session_id,
  filename,
  setUpdatedRecData,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [index, setIndex] = useState(-1);
  const [savedStatusList, setSavedStatusList] = useState([]);

  const Dataloading = () => {
    return (
      <div
        className={`flex shrink grow basis-0 w-[1%] items-center justify-center py-4`}
      >
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size="32px"
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (markDownContainerData) setRecData(markDownContainerData);
  }, [markDownContainerData]);

  const getChartValues = (jsonString) => {
    const chartData = jsonString;

    return <Charts chartData={chartData} />;
  };

  const chartStatistics = (children, item) => {
    let formatedResponse = (data) => {
      if (typeof data === "string" && data.includes("\\")) {
        try {
          return <MathJax inline={false}>{`\\[${data}\\]`}</MathJax>;
        } catch (error) {
          console.error("MathJax rendering error:", error);
          return data;
        }
      } else {
        return data;
      }
    };
    const jsonRegex = /```json\s*({[\s\S]*?})\s*```/g;
    ///```json\s*([\s\S]*?)\s*```/g
    let chartDataArray = [];

    if (item?.response) {
      let match;
      while ((match = jsonRegex.exec(item.response)) !== null) {
        try {
          const parsedData = JSON.parse(match[1]);
          chartDataArray.push(parsedData);
        } catch (error) {
          console.error("Invalid JSON:", match[1], error);
        }
      }
    }

    return Array.isArray(children)
      ? children.map((value) =>
          value?.type === "img" || value.type === "a"
            ? chartDataArray.map((chartData, ind) => (
                <div key={ind} className="sio-card-border !mt-3">
                  {getChartValues(chartData)}
                </div>
              ))
            : !value?.props && value.includes("Chart Data")
            ? null
            : value
        )
      : children?.type === "img" || children.type === "a"
      ? chartDataArray.map((chartData, ind) => (
          <div key={ind} className="sio-card-border !mt-3">
            {getChartValues(chartData)}
          </div>
        ))
      : !children?.props && children.includes("Chart Data")
      ? null
      : formatedResponse(children);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [recData, setRecData] = useState(markDownContainerData);

  const editInputRef = useRef(null);

  const currentUrl = encodeURIComponent(window.location.href);
  const emailShareUrl = `mailto:?subject=Check out this data!&body=Check out this link: ${currentUrl}`;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = (platform) => {
    if (platform === "email") {
      window.location.href = emailShareUrl;
    }
    handleClose();
  };

  function isTableData(data) {
    const hasTableElements = data.includes("|") && data.includes("---");
    return hasTableElements;
  }

  const handleDownload = (data) => {
    const prompt = data.find((item) => item.input)?.input || "";
    const fileContent = data
      .map((item, index) => {
        const inputText = item.input ? `Input:\n${item.input}` : "";
        const responseText = item.response ? `Response:\n${item.response}` : "";

        return (
          inputText + (inputText && responseText ? "\n\n" : "") + responseText
        );
      })
      .join("\n\n\n");

    const blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${prompt}.txt`);
  };

  const downloadIndividualResponse = (data) => {
    const responseData = data.find((item) => item.response)?.response || "";
    if (isTableData(responseData)) {
      const input = data.find((item) => item.input)?.input || "";
      if (!responseData) {
        return;
      }

      const tableData = responseData
        .split("\n")
        .filter((row) => row.startsWith("|"));

      const csvData = tableData
        .map((row) => {
          return row
            .replace(/^\||\|$/g, "")
            .split("|")
            .map((cell) => cell.trim())
            .join(",");
        })
        .join("\n");

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", input);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      handleDownload(data);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [editPrompt, setEditPrompt] = useState(null);

  const handleEditInputChange = useCallback(
    debounce((value) => {
      setEditPrompt(value);
    }, 0),
    []
  );

  const [likeMessageIds, setLikeMessageIds] = useState([]);
  const [dislikeMessageIds, setDislikeMessageIds] = useState([]);

  const handleLike = (id) => {
    setLikeMessageIds((prevLikeIds) => {
      const isAlreadyLiked = prevLikeIds.includes(id);

      if (isAlreadyLiked) {
        return prevLikeIds.filter((prevId) => prevId !== id);
      } else {
        setDislikeMessageIds((prevDislikeIds) =>
          prevDislikeIds.filter((prevId) => prevId !== id)
        );
        return [...prevLikeIds, id];
      }
    });
  };

  const LikeButton = (props) => {
    return (
      <Tooltip title="Good Response">
        <button
          onClick={async () => {
            handleLike(recData[props.ind]?.id);
            await likeDislikeMessage(recData[props.ind]?.id, "like");
          }}
          className={`sio-circle-icon bg-warning/20 size-8 shrink-0 ${
            likeMessageIds.includes(recData[props.ind]?.id) ||
            recData[props.ind]?.likes > 0
              ? "text-secondary-900"
              : "text-secondary-dark"
          }`}
        >
          <FiThumbsUp />
        </button>
      </Tooltip>
    );
  };

  const likeDislikeMessage = async (message_id, action) => {
    try {
      const token = await checkAndRefreshToken();
      const { data } = await getUserNames();
      const res = await fetch(
        `${devApis.PYTHON_BASE_URL}/like_dislike_message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            message_id: message_id,
            action: action,
            user_id: data.user.id,
          }),
        }
      );

      if (res.status === 200 || res.status === 201) {
        await res.json();
      }
    } catch (error) {
      console.error("Error bookmarking recommendation:", error.message);
      toast.error("Error bookmarking recommendation:", error.message);
    }
  };

  const handleDislike = (id) => {
    setDislikeMessageIds((prevDislikeIds) => {
      const isAlreadyDisliked = prevDislikeIds.includes(id);

      if (isAlreadyDisliked) {
        return prevDislikeIds.filter((prevId) => prevId !== id);
      } else {
        setLikeMessageIds((prevLikeIds) =>
          prevLikeIds.filter((prevId) => prevId !== id)
        );
        return [...prevDislikeIds, id];
      }
    });
  };

  const DislikeButton = (props) => {
    return (
      <Tooltip title="Bad Response">
        <button
          onClick={async () => {
            handleDislike(recData[props.ind]?.id);
            await likeDislikeMessage(recData[props.ind]?.id, "dislike");
          }}
          className={`sio-circle-icon bg-warning/20 size-8 shrink-0 ${
            dislikeMessageIds.includes(recData[props.ind]?.id) ||
            recData[props.ind]?.disLikes > 0
              ? "text-red-500"
              : "text-secondary-dark"
          }`}
        >
          <FiThumbsDown />
        </button>
      </Tooltip>
    );
  };

  const handleEditPrompt = (e) => {
    handleEditInputChange(e.target.value);
  };

  const editMessage = async (user_id, message_id, prompt, filename) => {
    let updatedFileName;
    if (typeof filename === "string") {
      updatedFileName = filename.split("_").slice(1).join("_");
    } else if (Array.isArray(filename)) {
      updatedFileName = filename.map((name) =>
        name.split("_").slice(1).join("_")
      );
    }
    try {
      const token = await checkAndRefreshToken();
      const res = await fetch(`${devApis.PYTHON_BASE_URL}/edit_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_id: message_id,
          new_content: prompt,
          user_id: user_id,
          filename: updatedFileName,
        }),
      });

      if (res.status === 200 || res.status === 201) {
        const data = await res.json();
        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data.answer,
            id: data.message_id,
          };
          return updatedArray;
        });
        setUpdatedRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data.answer,
            id: data.message_id,
          };
          return updatedArray;
        });
      }
    } catch (error) {
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error Editing prompt:", error.message);
    }
  };

  const BouncingDots = () => {
    return (
      <div className="sio-loader-container">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    );
  };

  const mathJaxConfig = {
    loader: { load: ["input/tex", "output/chtml"] },
  };

  const Format = (markDownData) => {
    return (
      markDownData &&
      markDownData.length > 0 &&
      markDownData?.map((item, ind) => (
        <div
          id={item?.input ? `inp${ind}` : `res${ind}`}
          className={`flex ${
            item?.input ? "items-end justify-end sio-salf" : "items-start"
          } relative`}
          key={ind}
        >
          {item?.LoadingData && <Dataloading />}
          {item?.response && (
            <img
              src={icon}
              alt="response_strived_icon"
              className={`md:p-2 md:py-3 bg-black rounded-full me-3 md:me-4 size-7 
                flex-shrink-0 md:size-10 ${ind === 0 && "invisible"}`}
            />
          )}
          <div className={`flex flex-col self-center overflow-hidden`}>
            {item?.response === "Loading..." ? (
              <BouncingDots />
            ) : (
              <MathJaxContext config={mathJaxConfig} onLoad={() => null}>
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  className={`prose prose-sm sio-editor-content lg:prose-lg xl:prose-xl max-w-none
                      sm:text-[13px] md:text-[13px] 2xl:text-[16px] 3xl:text-[19px] flex-col ${
                        isClicked && index === ind && "hidden"
                      }`}
                  components={{
                    ul: ({ node, children, ...props }) => (
                      <ul
                        className="sio-list-disc sio-list-secondary"
                        {...props}
                      >
                        {children}
                      </ul>
                    ),
                    li: ({ node, children, ...props }) => (
                      <li className="" {...props}>
                        {chartStatistics(children, item)}
                      </li>
                    ),
                    table: ({ node, children, ...props }) => (
                      <div className={``}>
                        <TableContainer>{children}</TableContainer>
                      </div>
                    ),
                    p: ({ node, children, ...props }) => (
                      <p {...props}>
                        {Array.isArray(children) && children
                          ? children.map((child) =>
                              child?.props && child?.props?.children
                                ? child?.props?.children.includes(
                                    "Chart Data"
                                  ) && null
                                : child?.children &&
                                  child?.children.includes("Chart Data") &&
                                  null
                            )
                          : children?.children &&
                            children?.children.includes("Chart Data") &&
                            null}
                        {chartStatistics(children, item)}
                        {item?.input && (
                          <button
                            className="ms-2 relative"
                            onClick={async () => {
                              const { saved_status } = await bookmarkQuestions(
                                item?.id
                              );
                              saved_status
                                ? setSavedStatusList((prevList) => [
                                    ...prevList,
                                    item?.id,
                                  ])
                                : setSavedStatusList((prevList) => {
                                    return prevList.filter(
                                      (id) => id !== item?.id
                                    );
                                  });
                            }}
                          >
                            {item?.saved_status ||
                            savedStatusList.includes(item?.id) ? (
                              <svg
                                className="size-[20px] -mr-[4px] -mt-0.5"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.5 2C4.08333 2 3 3.16667 3 4.58333V16.9167C3 17.5833 3.41667 18.25 4 18.5C4.25 18.5833 4.41667 18.6667 4.66667 18.6667C5.08333 18.6667 5.41667 18.5 5.75 18.25L9.58333 14.6667L13.5 18.25C14 18.6667 14.6667 18.8333 15.25 18.5833C15.8333 18.3333 16.25 17.6667 16.25 17V4.58333C16.3333 3.16667 15.25 2 13.8333 2H5.5Z"
                                  fill="#F5B937"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="13"
                                height="16"
                                viewBox="0 0 13 16"
                                fill="none"
                              >
                                <path
                                  d="M2.3951 0C1.03787 0 0 1.11771 0 2.47493V14.2907C0 14.9294 0.399182 15.5681 0.958038 15.8076C1.19755 15.8875 1.35722 15.9673 1.59673 15.9673C1.99591 15.9673 2.31526 15.8076 2.63461 15.5681L6.30708 12.1352L10.0594 15.5681C10.5384 15.9673 11.1771 16.127 11.736 15.8875C12.2948 15.648 12.694 15.0093 12.694 14.3706V2.47493C12.7738 1.11771 11.736 0 10.3787 0H2.3951ZM11.1771 2.47493V14.3706L7.4248 10.9376C7.10545 10.6981 6.70627 10.5384 6.38692 10.5384C5.98774 10.5384 5.66839 10.6981 5.34905 10.9376L1.59673 14.2907V2.47493C1.59673 1.99591 1.91608 1.59673 2.3951 1.59673H10.3787C10.8578 1.59673 11.1771 1.99591 11.1771 2.47493Z"
                                  fill="black"
                                />
                              </svg>
                            )}
                          </button>
                        )}
                      </p>
                    ),
                    code: ({ node, children, ...props }) => null,
                  }}
                >
                  {item?.response || item?.input}
                </Markdown>
              </MathJaxContext>
            )}

            {item?.input && !isClicked && (
              <Button
                variant="dark"
                size="xs"
                onClick={() => {
                  setIsClicked(true);
                  setIndex(ind);
                  setEditPrompt(item.input);
                  setTimeout(() => {
                    editInputRef.current.value = item.input;
                  }, 100);
                }}
                className="soi-btn-flat flex gap-1 items-center mt-1 self-end"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.90132 13.726C2.06132 13.726 2.09333 13.71 2.23732 13.678L5.11732 13.102C5.42132 13.022 5.72533 12.878 5.96532 12.638L12.9413 5.662C14.0133 4.59 14.0133 2.75 12.9413 1.678L12.3493 1.054C11.2773 -0.018 9.42132 -0.018 8.34933 1.054L1.37332 8.046C1.14932 8.27 0.989325 8.59 0.909325 8.894L0.301325 11.806C0.221325 12.35 0.381325 12.878 0.765325 13.262C1.06932 13.566 1.51732 13.726 1.90132 13.726ZM2.44532 9.198L9.42132 2.206C9.88533 1.742 10.7333 1.742 11.1813 2.206L11.7893 2.814C12.3333 3.358 12.3333 4.126 11.7893 4.654L4.82933 11.646L1.86932 12.142L2.44532 9.198Z" />
                </svg>
                EDIT
              </Button>
            )}

            {isClicked && index === ind && (
              <div className="sio-chat-edit-col">
                <TextField
                  ref={editInputRef}
                  value={editPrompt}
                  multiline
                  minRows={1}
                  maxRows={6}
                  className="w-[100%] bg-transparent focus:outline-none mb-3"
                  id="prompt_data"
                  onChange={(e) => handleEditPrompt(e)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "0",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <div className="flex items-center justify-end gap-2">
                  <Button
                    variant="dark"
                    size="xs"
                    className=""
                    onClick={() => setIsClicked(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    size="xs"
                    className=""
                    onClick={async () => {
                      const { data } = await getUserNames();
                      setIsClicked(false);
                      let currentItem;
                      setRecData((prevArray) => {
                        const updatedArray = [
                          ...prevArray.slice(0, ind),
                          { input: editPrompt },
                          { response: "Loading..." },
                        ];
                        return updatedArray;
                      });
                      setUpdatedRecData((prevArray) => {
                        currentItem = prevArray[index + 1].id;
                        const updatedArray = [
                          ...prevArray.slice(0, ind),
                          { input: editPrompt },
                          { response: "Loading..." },
                        ];
                        editMessage(
                          data?.user?.id,
                          currentItem,
                          editPrompt,
                          filename
                        );
                        return updatedArray;
                      });

                      if (item && item.id) {
                        editMessage(
                          data?.user?.id,
                          item.id,
                          editPrompt,
                          filename
                        );
                      }
                    }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            )}
            {item?.response && item?.response !== "Loading..." && (
              <div className="flex gap-3 mb-10 mt-2 items-center">
                <LikeButton ind={ind} handleLikeClick={handleLike} />

                <DislikeButton ind={ind} handleDislikeClick={handleDislike} />

                <Tooltip title="Share via Email">
                  <div className="text-gray-500 hover:text-gray-700 hover:cursor-pointer">
                    <button
                      className="sio-circle-icon bg-warning/20 size-8 shrink-0 text-secondary-dark"
                      onClick={handleClick}
                    >
                      <FiShare />
                    </button>
                    <Menu
                      id="share-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleShare("email")}>
                        <MdEmail />
                      </MenuItem>
                    </Menu>
                  </div>
                </Tooltip>

                <Tooltip title="Download Response">
                  <button
                    className="sio-circle-icon bg-warning/20 size-8 shrink-0 text-secondary-dark"
                    onClick={() => {
                      downloadIndividualResponse([
                        markDownData[ind - 1],
                        markDownData[ind],
                      ]);
                    }}
                  >
                    <FiDownload />
                  </button>
                </Tooltip>

                <Tooltip title="Regenerate">
                  <button
                    className="sio-circle-icon bg-warning/20 size-8 shrink-0 text-secondary-dark"
                    onClick={async () => {
                      let currentItem;
                      if (item && item.id) {
                        currentItem = item?.id;
                      }
                      try {
                        setRecData((prevArray) => {
                          const updatedArray = [
                            ...prevArray.slice(0, ind),
                            { response: "Loading..." },
                          ];
                          return updatedArray;
                        });
                        setUpdatedRecData((prevArray) => {
                          const updatedArray = [
                            ...prevArray.slice(0, ind),
                            { response: "Loading..." },
                          ];
                          return updatedArray;
                        });

                        const getPromtIndex =
                          recData.findIndex((item) => item.id === currentItem) -
                          1;
                        const { input } = recData[getPromtIndex];
                        const { answer } = await regenerateResponse(
                          item.id,
                          input,
                          session_id.current,
                          filename
                        );
                        setRecData((prevArray) => {
                          const updatedArray = [...prevArray];
                          updatedArray[updatedArray.length - 1] = {
                            response: answer,
                            // id: data.message_id,
                          };
                          return updatedArray;
                        });
                        setUpdatedRecData((prevArray) => {
                          const updatedArray = [...prevArray];
                          updatedArray[updatedArray.length - 1] = {
                            response: answer,
                          };
                          return updatedArray;
                        });
                      } catch (error) {
                        setRecData((prevArray) => prevArray.slice(0, -2));
                        console.error("Error regenerating prompt:", error);
                      }
                    }}
                  >
                    <HiOutlineRefresh />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      ))
    );
  };

  return Format(markDownContainerData);
};

export default MarkdownComponent;
