import React from "react";
import Chart from "react-apexcharts";

const Charts = ({ chartData }) => {
  if (
    chartData?.type === "Pie" ||
    chartData?.type === "pie" ||
    chartData?.type === "donut" ||
    chartData?.type === "doughnut"
  ) {
    let labelsData, seriesData;

    labelsData = chartData?.data?.labels;
    seriesData = chartData?.data?.datasets?.flatMap((item) => item?.data);

    const options = {
      title: {
        text: chartData?.options?.title?.text,
        align: "center",
        style: {
          fontSize: "15px",
          fontWeight: "bold",
          color: "#CF037E",
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          customScale: 1,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: "10px",
          fontFamily: "Manrope, sans-serif",
          fontWeight: "bold",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      labels: labelsData,
      colors: ["#4DC39B", "#47A4EA", "#CF5768", "#E6B350", "#8670D3"],
    };

    const colors = ["green", "blue", "red", "orange", "perpal"];

    return (
      <div className="flex flex-col md:flex-row">
        <div className="chart-container -ml-5 -mr-2 -mb-1">
          <Chart
            options={options}
            series={seriesData}
            type="donut"
            width="260"
          />
        </div>
        <div className="grow flex items-center">
          <div className="grid w-full grid-cols-2 sio-statistics-list">
            {seriesData.map((value, ind) => (
              <div
                className={`sio-statistics-list-item border-b ${
                  ind % 2 === 0 ? "border-r" : ""
                } ${ind === seriesData.length - 1 ? "border-b-0" : ""}`}
              >
                <div className={`sio-status sio-status-${colors[ind]}`}>
                  <div className="flex items-center">
                    <p className="font-semibold text-secondary-dark">
                      {labelsData[ind]}
                    </p>
                    <span className="ml-auto font-semibold text-xs">
                      {value}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  if (
    chartData?.bar_chart_data ||
    chartData?.type === "Bar" ||
    chartData?.type === "bar"
  ) {
    let categoryData, seriesData;

    if (chartData?.bar_chart_data) {
      categoryData = chartData?.bar_chart_data?.labels;
      seriesData = [
        {
          name: Array.isArray(chartData?.bar_chart_data?.datasets?.label)
            ? chartData?.bar_chart_data?.datasets?.label
            : [chartData?.bar_chart_data?.datasets?.label],
          data: chartData?.bar_chart_data?.datasets?.flatMap(
            (value) => value.data
          ),
        },
      ];
    }

    if (chartData?.barChart) {
      categoryData = chartData?.barChart?.categories;
      seriesData = chartData?.barChart?.data.map((series) => ({
        name: series.name,
        data: series.data,
      }));
    }

    if (chartData?.type) {
      categoryData = chartData?.data?.labels;
      seriesData = chartData?.data?.datasets.map((value) => ({
        name: value.label,
        data: value?.data,
      }));
    }
    const options = {
      chart: {
        id: "bar-chart",
        toolbar: {
          show: false,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
      title: {
        text: chartData?.options?.title?.text,
        align: "left",
        style: {
          fontSize: "15px",
          fontWeight: "bold",
          color: "#CF037E",
        },
      },
      xaxis: {
        title: {
          text: chartData?.options?.scales?.x?.title?.text,
        },
        type: "string",
        categories: categoryData,
      },
      yaxis: {
        title: {
          text: chartData?.options?.scales?.y?.title?.text,
        },
        labels: {
          style: {
            colors: "#373D3F",
            fontSize: "10px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 400,
          },
        },
      },
    };

    return (
      <Chart options={options} series={seriesData} type="bar" height="300px" />
    );
  }
  if (chartData?.type === "line") {
    let categoryData, seriesData;

    categoryData = chartData?.data?.labels;
    seriesData = chartData?.data?.datasets.map((value) => ({
      name: value.label,
      data: value?.data,
    }));

    const options = {
      chart: {
        id: "line-chart",

        toolbar: {
          show: false,
        },
        type: "area",
        stacked: false,
        width: "100%",
        zoom: {
          type: "x",
          enabled: false,
          autoScaleYaxis: true,
        },
      },
      title: {
        text: chartData?.options?.title?.text,
        align: "left",
        style: {
          fontSize: "15px",
          fontWeight: "bold",
          color: "#CF037E",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [100, 100, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 4,
        colors: ["#47A4EA"],
      },
      yaxis: {
        title: {
          text: chartData?.options?.scales?.y?.title?.text,
        },
        labels: {
          style: {
            colors: "#373D3F",
            // fontSize: "10px",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 400,
          },
        },
      },
      tooltip: {
        shared: false,
      },
      xaxis: {
        title: {
          text: chartData?.options?.scales?.x?.title?.text,
        },
        type: "string",
        categories: categoryData,
      },
    };
    const series = seriesData;

    return <Chart options={options} series={series} height={300} />;
  }
};

export default Charts;
