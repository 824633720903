/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  getFilesList,
  getRecByS3Keys,
} from "../../../apis/recommendation/recommendation";
import Button from "../../../components/button";
import SuggestionsSidebar from "./suggestionsSidebar";
import SuggestionsContainer from "./suggestionsContainer";
import { useNavigate } from "react-router-dom";
import { StrivedContext } from "../../../App";
import { DataContext } from "../../../components/layout/layout";

const Suggestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredRec, setFilteredRec] = useState([]);
  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);

  const navigate = useNavigate();

  const { suggestions } = useContext(StrivedContext);
  const { setHighlightField } = useContext(DataContext);

  const getRecommendations = async (data) => {
    let recommendation;
    if (data) recommendation = data.recommendations.recommendations;
    if (!data) {
      const files = await getFilesList();
      const filteredFileData = files.filter(
        (file) => file.s3_key.includes(".csv") || file.s3_key.includes(".json")
      );
      const filteredS3Keys = filteredFileData.map((file) => file.s3_key);
      const data1 = await getRecByS3Keys(filteredS3Keys, filteredFileData);
      recommendation = data1?.recommendations.filter(
        (item) => !item?.is_combined
      );
    }

    setFilteredRec(
      recommendation.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      })
    );

    setSelectedSuggestion(
      recommendation.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      })[0]
    );
  };

  useEffect(() => {
    getRecommendations(suggestions);
    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("Suggestions");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    navigate("/");
  };

  const playGroundTopBar = () => {
    return (
      <div className="flex items-center shadow-md p-3 md:p-5 relative z-10">
        <Button
          className="soi-btn-fab btn-outline-dark me-2 size-8 flex lg:hidden"
          size={"xs"}
          onClick={handleModileSidebarToggle}
        >
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 12V10H11V12H7ZM3 7V5H15V7H3ZM0 2V0H18V2H0Z"
              fill="#111111"
              fillOpacity="0.74"
            />
          </svg>
        </Button>
        <h1 className="font-normal text-lg md:text-xl text-primary-dark">
          Suggestions
        </h1>
        <div className="items-center ms-auto flex">
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}
          >
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className={`h-full w-full flex flex-col overflow-hidden`}>
        {playGroundTopBar()}

        <div className={`flex flex-row overflow-hidden h-full`}>
          <SuggestionsSidebar
            isModileSidebarActive={isModileSidebarActive}
            recommendationData={filteredRec}
            setModileSidebarIsActive={setModileSidebarIsActive}
            setSelectedSuggestion={setSelectedSuggestion}
          />
          <SuggestionsContainer recommendationData={selectedSuggestion} />
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
