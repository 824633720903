// Button.js
import React, { useMemo } from "react";

const Button = ({
  type = "button",
  onClick,
  variant = "",
  size = "large",
  className = "",
  children,
  disabled = false,
}) => {
  // Variant CSS logic
  const getVariantClass = (variant) => {
    switch (variant) {
      case "primary":
        return "btn-primary";
      case "secondary":
        return "btn-secondary";
      case "danger":
        return "bg-btnDanger-gradient text-white hover:bg-red-700";
      case "dark":
        return "btn-dark";
      case "light":
        return "btn-light";
      case "default":
        return "btn-default";
      default:
        return "";
    }
  };

  // Size CSS logic using useMemo
  const sizeCss = useMemo(() => {
    switch (size) {
      case "xs":
        return "btn-xs"; // Small size style
      case "sm":
        return "btn-sm"; // Small size style
      case "md":
        return "w-1/2"; // Medium width style
      case "lg":
        return "btn-lg"; // Large width style
      case "full":
        return "w-full"; // Full width style
      default:
        return ""; // Default to large
    }
  }, [size]);

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`sio-btn ${getVariantClass(variant)} ${sizeCss} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
