/* eslint-disable no-unused-vars */
import axios from "axios";
import { checkAndRefreshToken } from "../../utils/authUtils";
import { computeSignature, getUserNames } from "../../utils";
import { devApis } from "../../constants/constant";
import * as XLSX from "xlsx";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
export const base_url = devApis.PYTHON_BASE_URL;

export const getAllRecommendations = async (accessToken) => {
  const getUUID = await getUserNames();
  const uuid = getUUID.data.org_uuid;
  const params = new URLSearchParams();
  params.append("org_uuid", uuid);
  const recommendationsUrl = `${
    devApis.GOLANG_BASE_URL
  }/get_all_recommendations?${params.toString()}`;

  try {
    const res = await fetch(recommendationsUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching recommendations:", error.message);
    return [];
  }
};

export const bookmarkRecommendation = async (config, accessToken) => {
  const bookmarkUrl = `${devApis.GOLANG_BASE_URL}/bookmark_recommendation`;

  try {
    const res = await fetch(bookmarkUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(config),
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error bookmarking recommendation:", error.message);
    throw new Error(error);
  }
};

export const getBookmarks = async (accessToken) => {
  const bookmarksUrl = `${devApis.GOLANG_BASE_URL}/get_bookmarks`;

  try {
    const res = await fetch(bookmarksUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching bookmarks:", error.message);
    return [];
  }
};

export const bookmarkDelete = async (config, accessToken) => {
  const deleteUrl = `${devApis.GOLANG_BASE_URL}/delete_bookmark`;

  try {
    const res = await fetch(deleteUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(config),
    });

    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const searchByQuery = async (searchQuery, accessToken) => {
  try {
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    const params = new URLSearchParams();
    params.append("org_uuid", uuid);
    const res = await fetch(
      `${
        devApis.GOLANG_BASE_URL
      }/get_all_recommendations?search_query=${searchQuery}&${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      throw new Error("401");
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const searchBookmarkByQuery = async (searchQuery, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.GOLANG_BASE_URL}/get_bookmarks?search_query=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getAllConversations = async (queryParams, accessToken) => {
  try {
    const res = await fetch(
      `${base_url}/get_all_conversations?${queryParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getChatHistory = async (nextQueryParams, accessToken) => {
  try {
    //${base_url}
    const res = await fetch(
      `${base_url}/get_chat_history?${nextQueryParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const feedbackUpvote = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(`${devApis.GOLANG_BASE_URL}/feedback_upvote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        recommendation_id: recommendationId,
      }),
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getUpvotes = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.GOLANG_BASE_URL}/feedback_upvote?recommendation_id=${recommendationId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error getting upvotes:", error.message);
    throw new Error(error);
  }
};

export const getDownvotes = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.GOLANG_BASE_URL}/feedback_downvote?recommendation_id=${recommendationId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error getting downvotes:", error.message);
    throw new Error(error);
  }
};

export const feedbackDownvote = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(`${devApis.GOLANG_BASE_URL}/feedback_downvote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        recommendation_id: recommendationId,
      }),
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const upload = async (formData, accessToken) => {
  try {
    const res = await fetch(`${devApis.PYTHON_BASE_URL}/upload_file`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const converCSVToTable = async (formData, accessToken) => {
  try {
    const res = await fetch(`${devApis.PYTHON_BASE_URL}/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getFIleRecommendation = async (fileRes, accessToken) => {
  try {
    const res = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_recommendations?s3_key=${fileRes.s3_key}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const startConversation = async (recommendationId, accessToken) => {
  try {
    const res = await fetch(`${devApis.PYTHON_BASE_URL}/start_conversation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        recommendation_id: recommendationId,
      }),
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const addMessageToSession = async (
  newSessionId,
  promptVlaue,
  accessToken,
  filename
) => {
  let updatedFileName;
  if (typeof filename === "string") {
    updatedFileName = filename.split("_").slice(1).join("_");
  } else if (Array.isArray(filename)) {
    updatedFileName = filename.map((name) =>
      name.split("_").slice(1).join("_")
    );
  }

  let filteredFileNames;
  if (Array.isArray(updatedFileName)) {
    filteredFileNames = [...new Set(updatedFileName)];
  } else {
    filteredFileNames = updatedFileName;
  }

  try {
    const res = await fetch(
      `${devApis.PYTHON_BASE_URL}/add_message_to_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          session_id: newSessionId,
          prompt: promptVlaue,
          filename: filteredFileNames,
        }),
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      console.error(`Failed to add message: ${res.status} ${res.statusText}`);
      throw new Error(`Failed to add message: ${res.status} ${res.statusText}`);
    }
  } catch (error) {
    console.error("Error adding message:", error.message);
    throw new Error(error);
  }
};

export const regenerateRecommendation = async (
  recommendationId,
  accessToken,
  filename
) => {
  const updatedFileName = filename.split("_").slice(1).join("_");
  try {
    const res = await fetch(
      `${devApis.PYTHON_BASE_URL}/api/recommendation/regenerate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          recommendation_id: recommendationId,
          filename: updatedFileName,
        }),
      }
    );
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error deleting bookmark:", error.message);
    throw new Error(error);
  }
};

export const getRecByS3Keys = async (data, fileData, sendDataToParent) => {
  if (data.length === 0) return;

  const filteredData = data.filter((s3Key) => s3Key.split(".").length > 1);

  let respData = {};
  const token = await checkAndRefreshToken();
  const secretKey = "wBLm_uRNsyuxHWZrIufExXRBI-uE0aDkjT7xsKLbn70=";
  try {
    if (data.length === 0) throw new Error("No s3_keys");
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    const params = new URLSearchParams();
    params.append("org_uuid", uuid);

    if (filteredData.length === 1) {
      const getRecommendationsSignature = computeSignature(
        secretKey,
        "GET",
        "/get_recommendations",
        { s3_key: filteredData[0], org_uuid: uuid },
        {},
        "",
        ""
      );

      const respS3 = await fetch(
        `${devApis.PYTHON_BASE_URL}/get_recommendations?s3_key=${
          filteredData[0]
        }&${params.toString()}`,
        {
          method: "GET",
          headers: {
            "x-signature": getRecommendationsSignature,
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const resData = await respS3.json();
      respData.recommendations = resData;
    }

    if (filteredData.length > 1) {
      const recIdCondition = fileData.map((files) =>
        filteredData.includes(files.s3_key)
      );
      const redId = fileData
        .filter((_, index) => recIdCondition[index])
        .map((file) => file.id);
      const combineResp = await fetch(
        `${
          devApis.PYTHON_BASE_URL
        }/generate_combined_recommendations?${params.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            s3_keys: [...new Set(filteredData)],
            recommendation_ids: redId,
          }),
        }
      );
      const combineData = await combineResp.json();
      const result = {
        recommendations: combineData.records,
      };
      if (sendDataToParent) sendDataToParent({ recommendations: result });
      return result;
    }

    const result = {
      recommendations: respData,
    };
    if (sendDataToParent) sendDataToParent(result);
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export const getFilesList = async () => {
  const token = await checkAndRefreshToken();
  const getUUID = await getUserNames();
  const uuid = getUUID.data.org_uuid;
  const params = new URLSearchParams();
  params.append("org_uuid", uuid);
  try {
    const res = await fetch(`${base_url}/uploaded_files?${params.toString()}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200 || res.status === 201) {
      const data = await res.json();

      const sortedFiles = data?.files?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      return sortedFiles;
    } else {
      // throw new Error(error);
      throw new Error();
    }
  } catch (error) {
    throw new Error(error);
  }
};

export async function CreateChildRecommendation(model) {
  try {
    model = JSON.stringify(model);
    const token = await checkAndRefreshToken();
    await axios.post(
      `${devApis.GOLANG_BASE_URL}/create_custom_child_recommendation`,
      model,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    fetchData(model);
    return model;
  } catch (error) {
    return error;
  }
}

export const fetchData = async () => {
  const token = await checkAndRefreshToken();
  const url = `${devApis.GOLANG_BASE_URL}/api/get_custom_child_recommendation`;

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (err) {
    return err;
  }
};

export const getFileName = async () => {
  const token = await checkAndRefreshToken();
  const url = `${devApis.GOLANG_BASE_URL}/api/file_types`;

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return data;
  } catch (err) {
    console.log(err.message);
  }
};

export const editChild = async (file, accessToken) => {
  try {
    const parent_id = file.parent_id;
    const response = await fetch(
      `${devApis.GOLANG_BASE_URL}/api/edit_custom_child_recommendation/${parent_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(file),
      }
    );
    const updatedFile = await response.json();
    return updatedFile;
  } catch (err) {
    return err;
  }
};

export const deleteChild = async (file, accessToken) => {
  try {
    const response = await fetch(
      `${devApis.GOLANG_BASE_URL}/api/delete_custom_child_recommendation/${file.ID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    await response.json();
  } catch (err) {
    return err;
  }
};

export async function CreateCustomRecommendation(model) {
  try {
    model = JSON.stringify(model);
    const token = await checkAndRefreshToken();
    const response = await axios.post(
      `${devApis.GOLANG_BASE_URL}/create_custom_parent_recommendation`,
      model,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    fetchData();
    return response.data.parent_recommendation;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const recommendationVisited = async (id) => {
  const url = `${devApis.GOLANG_BASE_URL}/api/mark_recommendation_as_viewed`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ recommendation_id: id }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const bookmarkVisited = async (id) => {
  const url = `${devApis.GOLANG_BASE_URL}/api/mark_bookmark_as_viewed`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ bookmark_id: id }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const selectRecommendations = async (recId) => {
  const url = `${devApis.PYTHON_BASE_URL}/api/select`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        recommendation_id: recId,
        selected: true,
      }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const selectAllRecommendations = async () => {
  const url = `${devApis.PYTHON_BASE_URL}/api/selectAll`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        selected: true,
      }),
    });
    if (response.status === 200 || response.status === 201) {
      await response.json();
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchDataCustomChildRecommendations = async (fileType) => {
  const queryParams = new URLSearchParams();
  queryParams.append("file_type", fileType);
  const decoded = fileType.replace(/[{}]/g, "");
  const url = `${devApis.GOLANG_BASE_URL}/api/get_custom_child_recommendation?file_type=${decoded}`;
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return {
        childData: data?.custom_child_recommendations?.slice(0, 2),
        hasChild: true,
      };
    } else {
      return { childData: [], hasChild: false };
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const newConversation = async (promptText) => {
  try {
    const promptResponse = await fetch(
      `${devApis.PYTHON_BASE_URL}/start_conversation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: JSON.stringify({
          recommendation_id: 3,
        }),
      }
    );
    const data = await promptResponse.json();
    const newSessionId = data.session_id;

    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/add_message_to_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: JSON.stringify({
          session_id: newSessionId,
          prompt: promptText,
        }),
      }
    );

    if (response.status === 200 || response.status === 201) {
      const data2 = await response.json();
      return data2;
    } else {
      console.error(
        `Failed to add message: ${response.status} ${response.statusText}`
      );
      throw new Error(
        `Failed to add message: ${response.status} ${response.statusText}`
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const regenerateResponse = async (
  messageId,
  input,
  sessionId,
  filename
) => {
  let updatedFileName;
  const token = await checkAndRefreshToken();
  const getUUID = await getUserNames();
  const uuid = getUUID.data.org_uuid;

  if (typeof filename === "string") {
    updatedFileName = filename.split("_").slice(1).join("_");
  } else if (Array.isArray(filename)) {
    updatedFileName = filename.map((name) =>
      name.split("_").slice(1).join("_")
    );
  }
  try {
    const promptResponse = await fetch(
      `${devApis.PYTHON_BASE_URL}/refresh_message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_id: messageId,
          session_id: sessionId,
          filename: updatedFileName,
          user_question: input,
          org_uuid: uuid,
        }),
      }
    );
    // const data = await promptResponse.json();
    // return data;
    if (promptResponse.status === 200 || promptResponse.status === 201) {
      const data = await promptResponse.json();
      return data;
    } else {
      console.error(
        `Failed to add message: ${promptResponse.status} ${promptResponse.statusText}`
      );
      throw new Error(
        `Failed to add message: ${promptResponse.status} ${promptResponse.statusText}`
      );
    }
  } catch (error) {
    console.error("Error adding message:", error.message);
    throw new Error(error);
  }
};

export const bookmarkQuestions = async (messageId) => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(`${devApis.PYTHON_BASE_URL}/save_message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message_id: messageId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSavedQuestions = async () => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_saved_messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteSavedQuestions = async (messageId) => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(`${devApis.PYTHON_BASE_URL}/delete_message`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message_id: messageId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteConversations = async (id) => {
  const token = await checkAndRefreshToken();
  try {
    const params = new URLSearchParams();
    params.append("recommendation_id", id);
    const response = await fetch(
      `${
        devApis.PYTHON_BASE_URL
      }/delete_conversations_by_recommendation?${params.toString()}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const saveAiChildRecommendations = async (
  recData,
  message_id,
  field
) => {
  console.log(recData);
  const token = await checkAndRefreshToken();
  try {
    if (recData.length > 1) {
      const response = await fetch(
        `${devApis.PYTHON_BASE_URL}/toggle_saved_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ message_id: message_id, field: field }),
        }
      );
      const data = await response.json();
      return data;
    }
    if (recData.length === 1) {
      const response = await fetch(
        `${devApis.PYTHON_BASE_URL}/update_saved_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            recommendation_id: recData[0].id,
            type: field,
          }),
        }
      );
      const data = await response.json();
      return data;
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteFile = async (s3_keys) => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(`${devApis.PYTHON_BASE_URL}/delete_files`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ s3_keys: s3_keys }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const downloadFile = async (s3_key, fileName) => {
  const token = await checkAndRefreshToken();
  try {
    const response = await fetch(`${devApis.PYTHON_BASE_URL}/download_file`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        s3_key: s3_key,
      }),
    });

    const { download_url } = await response.json();

    if (download_url) {
      const link = document.createElement("a");
      link.href = download_url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("File URL not found in response");
    }
  } catch (error) {
    throw new Error(error);
  }
};

// /view_file

// export const viewFile = async (s3_key, fileName) => {
//   const token = await checkAndRefreshToken();

//   // Get file extension
//   const fileExtension = fileName.split(".").pop().toLowerCase();

//   try {
//     const response = await fetch(`${devApis.PYTHON_BASE_URL}/view_file`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify({
//         s3_key: s3_key,
//       }),
//     });

//     const { view_url } = await response.json();

//     if (!view_url) {
//       throw new Error("File URL not found in response");
//     }

//     // Fetch the file content
//     const fileResponse = await fetch(view_url);
//     const blob = await fileResponse.blob();

//     switch (fileExtension) {
//       case "csv":
//       case "xlsx":
//       case "xls":
//         // Read the file data
//         const arrayBuffer = await blob.arrayBuffer();
//         const workbook = XLSX.read(arrayBuffer, { type: "array" });

//         // Get the first worksheet
//         const worksheetName = workbook.SheetNames[0];
//         const worksheet = workbook.Sheets[worksheetName];

//         // Convert to HTML table
//         const html = XLSX.utils.sheet_to_html(worksheet);

//         // Create and open a new window with the table
//         const newWindow = window.open("", "_blank");
//         newWindow.document.write(`
//           <!DOCTYPE html>
//           <html>
//             <head>
//               <title>${fileName}</title>
//               <style>
//                 body {
//                   font-family: Arial, sans-serif;
//                   padding: 20px;
//                   margin: 0;
//                 }
//                 table {
//                   border-collapse: collapse;
//                   width: 100%;
//                   margin-top: 20px;
//                 }
//                 th, td {
//                   border: 1px solid #ddd;
//                   padding: 8px;
//                   text-align: left;
//                 }
//                 th {
//                   background-color: #f4f4f4;
//                   position: sticky;
//                   top: 0;
//                 }
//                 tr:nth-child(even) {
//                   background-color: #f9f9f9;
//                 }
//                 tr:hover {
//                   background-color: #f5f5f5;
//                 }
//                 .container {
//                   max-width: 100%;
//                   overflow-x: auto;
//                 }
//                 .header {
//                   padding: 10px 0;
//                   border-bottom: 1px solid #ddd;
//                   margin-bottom: 20px;
//                   display: flex;
//                   justify-content: space-between;
//                   align-items: center;
//                 }
//                 .download-btn {
//                   padding: 8px 16px;
//                   background-color: #4CAF50;
//                   color: white;
//                   border: none;
//                   border-radius: 4px;
//                   cursor: pointer;
//                 }
//                 .download-btn:hover {
//                   background-color: #45a049;
//                 }
//               </style>
//             </head>
//             <body>
//               <div class="header">
//                 <h2>${fileName}</h2>
//                 <button class="download-btn" onclick="downloadFile()">Download</button>
//               </div>
//               <div class="container">
//                 ${html}
//               </div>
//               <script>
//                 function downloadFile() {
//                   const link = document.createElement('a');
//                   link.href = '${view_url}';
//                   link.download = '${fileName}';
//                   document.body.appendChild(link);
//                   link.click();
//                   document.body.removeChild(link);
//                 }
//               </script>
//             </body>
//           </html>
//         `);
//         newWindow.document.close();
//         break;

//       default:
//         // For other file types, download directly
//         const link = document.createElement("a");
//         link.href = view_url;
//         link.download = fileName;
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     }
//   } catch (error) {
//     console.error("Error viewing file:", error);
//     throw new Error(`Failed to view file: ${error.message}`);
//   }
// };

export const viewFile = async (s3_key, fileName, googleAccessToken) => {
  const token = await checkAndRefreshToken();
  // Get file extension
  const fileExtension = fileName.split(".").pop().toLowerCase();

  try {
    // Step 1: Fetch the file from the S3 URL
    const response = await fetch(`${devApis.PYTHON_BASE_URL}/view_file`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        s3_key: s3_key,
      }),
    });

    const { view_url } = await response.json();

    if (!view_url) {
      throw new Error("File URL not found in response");
    }

    const fileResponse = await fetch(view_url);
    const blob = await fileResponse.blob();

    // Step 2: Upload the file to Google Drive
    // const googleAccessToken = await getGoogleAccessToken(); // Implement this to get the OAuth token
    const metadata = {
      name: fileName,
      mimeType: "application/vnd.google-apps.spreadsheet",
    };

    const fileData = new Blob([fileResponse], { type: "text/csv" });

    const formData = new FormData();
    formData.append(
      "metadata",
      new Blob([JSON.stringify(metadata)], { type: "application/json" })
    );
    formData.append("file", fileData);

    const uploadResponse = await fetch(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${googleAccessToken}`,
        },
        body: formData,
      }
    );

    const uploadData = await uploadResponse.json();

    if (!uploadData.id) {
      throw new Error("Failed to upload file to Google Drive");
    }

    // Step 3: Generate the Google Sheets URL
    const googleSheetsUrl = `https://docs.google.com/spreadsheets/d/${uploadData.id}/edit`;
    window.open(googleSheetsUrl, "_blank");
  } catch (error) {
    console.error("Error opening file in Google Sheets:", error);
    throw new Error(`Failed to open file: ${error.message}`);
  }
};

export const editRecommendation = async (file, fileRecId) => {
  try {
    const token = await checkAndRefreshToken();
    const response = await fetch(
      `${devApis.GOLANG_BASE_URL}/api/file_type/${fileRecId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(file),
      }
    );
    const updatedFile = await response.json();
    alert("Successfully Updated Record");
    return updatedFile;
  } catch (error) {
    console.error("Error updating file:", error);
    alert("Failed to update record. Please try again.");
  }
};

export const getQuestionPromptsData = async () => {
  try {
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    const params = new URLSearchParams();
    params.append("org_uuid", uuid);
    const token = await checkAndRefreshToken();
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_custom_prompt?${params.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("Error fetching question prompts:", error);
    throw new Error(`Failed to fetch question prompts: ${error.message}`);
  }
};

export const addOrViewQuestion = async (prompt, s3Key, stackholders) => {
  try {
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    const params = new URLSearchParams();
    params.append("org_uuid", uuid);
    params.append("custom_parent_recommendation", prompt);
    params.append("s3_key", s3Key);
    params.append("stackholders", stackholders);
    const token = await checkAndRefreshToken();
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_recommendations?${params.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.json();
  } catch (error) {
    console.error("Error adding new question:", error);
    throw new Error(`Failed to add new question: ${error.message}`);
  }
};

export const editQuestion = async (prompt, fileName, id) => {
  try {
    const token = await checkAndRefreshToken();
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/edit_recommendations`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          recommendation_id: id,
          custom_parent_recommendation: prompt,
          filename: fileName,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error adding new question:", error);
    throw new Error(`Failed to add new question: ${error.message}`);
  }
};

export const getPromptMessages = async (queryParams) => {
  try {
    const token = await checkAndRefreshToken();
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_human_messages?${queryParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error adding new question:", error);
    throw new Error(`Failed to add new question: ${error.message}`);
  }
};

export const getSavedPromptResponse = async (queryParams) => {
  try {
    const token = await checkAndRefreshToken();
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_answer?${queryParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching saved prompt responses:", error);
    throw new Error(`Failed to fetch saved prompt responses: ${error.message}`);
  }
};

// /get_saved_status

export const getInitialChildSavedStatus = async (id) => {
  try {
    const token = await checkAndRefreshToken();
    const response = await fetch(
      `${devApis.PYTHON_BASE_URL}/get_saved_status/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching saved prompt responses:", error);
    throw new Error(`Failed to fetch saved prompt responses: ${error.message}`);
  }
};
