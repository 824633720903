// /* eslint-disable no-unused-vars */
import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { computeSignature, getUserNames } from "../utils";
import { devApis } from "../constants/constant";
import {
  addMessageToSession,
  getFilesList,
} from "../apis/recommendation/recommendation";
import { checkAndRefreshToken } from "../utils/authUtils";
import { DataContext } from "./layout/layout";
import Cookies from "js-cookie";

const ChatInputField = ({ handleHomePage, setIsLoading, isLoading }) => {
  const [file, setFile] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [fileNames, setFileNames] = useState(null);

  const { setSendUploadedFileData, handleDataFromLanding } =
    useContext(DataContext);

  useEffect(() => {
    getFileNames();
  }, []);

  let fileRes;

  const navigate = useNavigate();

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const getFileNames = async () => {
    const files = await getFilesList();
    const fileNames = files.map((file) => file.s3_key);
    setFileNames(fileNames);
  };

  const fileUploadRequest = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const getUUID = await getUserNames();
      const orgUuid = getUUID.data.org_uuid;
      formData.append("org_uuid", orgUuid);
      const secretKey = "wBLm_uRNsyuxHWZrIufExXRBI-uE0aDkjT7xsKLbn70=";

      const fileContent = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryString = reader.result;
          resolve(binaryString);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
      });

      const fileUploadsignature = computeSignature(
        secretKey,
        "POST",
        "/upload_file",
        {},
        { org_uuid: orgUuid },
        "",
        fileContent
      );
      try {
        const fileUploadResponse = await fetch(
          `${devApis.PYTHON_BASE_URL}/upload_file`,
          {
            method: "POST",
            headers: {
              "x-signature": fileUploadsignature,
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: formData,
          }
        );

        const fileUploadData = await fileUploadResponse.json();
        fileRes = fileUploadData;
        setSendUploadedFileData(fileRes);
        const response = await fetch(
          `${devApis.PYTHON_BASE_URL}/convert_csv_to_table`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: formData,
          }
        );

        await response.json();

        setIsLoading(false);
      } catch (error) {
        toast.error("error:", error.message);
        setIsLoading(false);
      }
    } else {
      try {
        const promptResponse = await fetch(
          `${devApis.PYTHON_BASE_URL}/start_conversation`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: JSON.stringify({
              recommendation_id: 0,
            }),
          }
        );

        const data = await promptResponse.json();
        const newSessionId = data.session_id;

        if (newSessionId) {
          const token = await checkAndRefreshToken();
          const response = await addMessageToSession(
            newSessionId,
            prompt,
            token,
            fileNames
          );

          handleHomePage(prompt);
          setIsLoading(false);
          handleDataFromLanding({ recommendations: [] }, []);
          navigate("/askmeanything", {
            state: {
              sessionId: newSessionId,
              promptData: response?.answer,
              promptInput: prompt,
              fileName: fileNames,
              aiChildRecommendation: [
                response?.qualitative_question,
                response?.quantitative_question,
              ],
              message_id: response?.message_id,
            },
          });
        }
      } catch (error) {
        console.error("Error during API calls:", error);
        toast.error("error:", error.message);
        return null;
      }
    }

    setFile(null);
  };

  const handleSubmit = async () => {
    if (!prompt || prompt.trim().length === 0) {
      toast("Please provide valid input");
      return;
    }
    setIsLoading(true);
    await fileUploadRequest();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
    } else if (e.key === "Enter" && e.shiftKey) {
      return;
    }
  };

  return (
    <div className="flex flex-col w-full">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <>
          <div className="absolute top-3 left-0 w-full -mt-2"></div>
          <div className="flex items-center justify-between">
            <TextField
              className="border-none w-full text-md focus:outline-none"
              multiline
              minRows={1}
              maxRows={8}
              placeholder="Ask me anything..."
              onChange={handlePrompt}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "8px 8px 8px 16px",
                  "& fieldset": {
                    border: "none",
                  },
                }
              }}
            />
            <button
              className="soi-btn soi-btn-fab btn-primary shrink-0 my-2 me-2 self-end"
              type="button"
              onClick={handleSubmit}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.870045 2.36133C0.753378 1.89533 0.919378 1.40467 1.29404 1.104C1.66871 0.803334 2.18404 0.748668 2.61338 0.963334L14.4867 6.9C14.9034 7.108 15.1667 7.534 15.1667 8C15.1667 8.466 14.9034 8.892 14.4867 9.1L2.61338 15.0367C2.18404 15.2513 1.66871 15.1967 1.29404 14.896C0.919378 14.5953 0.753378 14.1047 0.870045 13.6387L2.11338 8.66667L9.33338 8L2.11338 7.33333L0.870045 2.36133Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatInputField;
