/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from "react";
import signupLogo from "../../assets/strived-logo-icon.svg";
// import googleLogo from "../../assets/google-logo.png";
// import Checkbox from "@mui/material/Checkbox";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  // IconButton,
  // InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import Button from "../../components/button";
// import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
  getOrganizationList,
  loginUser,
  responseGoogle,
  verifyLogin,
} from "../../apis/login/login";
import { addAccount } from "../../apis/organization/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "@react-oauth/google";

import { ThemeProvider, useTheme } from "@mui/material/styles";
import { lightTheme, darkTheme } from "../../theme";
import Otp from "../otp/otp";
import Cookies from "js-cookie";
import { URL_POSTFIX } from "../../constants/constant";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");

  // const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgUuid, setSelectedOrgUuid] = useState("");
  const [orgUuidList, setOrganisationUuidList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [otp, setOtp] = useState("");

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handleEmailContinue = async () => {
    if (email) {
      setEmailError("");
      try {
        const getOrganizationListResp = await getOrganizationList(email);
        if (getOrganizationListResp?.status === 200) {
          let organization_list = getOrganizationListResp?.data?.list;
          const orgDetail = getOrganizationListResp?.data?.details;
          setOrgList(organization_list);
          setOrganisationUuidList(orgDetail);
          if (organization_list.length === 1) {
            setSelectedOrg(organization_list[0]);
            setSelectedOrgUuid(orgDetail[0]?.org_uuid);
            const loginResp = await loginUser({
              email: email,
              org_name: organization_list[0],
              org_uuid: orgDetail[0]?.org_uuid,
            });
            if (loginResp?.status === 200) {
              toast.success(loginResp?.data?.Status);
              setStep(3);
            } else {
              toast.error(loginResp?.data?.message);
            }
          } else {
            setStep(2);
          }
        } else {
          toast.error(getOrganizationListResp?.data?.message);
          // setEmailError("User is not registered");
        }
      } catch (e) {
        toast.error(e?.message);
        console.log(e);
      }
    } else {
      toast.error("Email is required");
      setEmailError("Email is required");
    }
  };

  const handleOrgContinue = async () => {
    if (selectedOrg) {
      const loginResp = await loginUser({
        email: email,
        org_name: selectedOrg,
        org_uuid: selectedOrgUuid,
      });
      if (loginResp?.status === 200) {
        setStep(3);
      } else {
        toast.error(loginResp?.data?.message);
      }
    }
  };

  // const handleLogin = async () => {
  //   let updatedData = await verifyLogin({
  //     email: email,
  //     org_name: selectedOrg,
  //     org_uuid: selectedOrgUuid,
  //     otp: otp,
  //   });
  //   if (updatedData.status === 200) {
  //     const decodedToken = jwt_decode(updatedData?.data?.token);
  //     if (decodedToken !== undefined) {
  //       const user = decodedToken.data;
  //       let userDetails = {
  //         org_uuid: user.org_uuid,
  //         user_uuid: user.user.user_uuid,
  //         email: user.user.email,
  //       };

  //       const addPayload = {
  //         email: email,
  //         org_name: selectedOrg,
  //         org_uuid: selectedOrgUuid,
  //         user_uuid: user.user.user_uuid,
  //       };
  //       const deviceUuid = localStorage.getItem("device_uuid");
  //       if (deviceUuid) {
  //         addPayload.device_uuid = deviceUuid;
  //       }
  //       const addNewacc = await addAccount(addPayload);
  //       if (addNewacc.status === 200) {
  //         localStorage.setItem("device_uuid", addNewacc?.data?.device_uuid);
  //         localStorage.setItem("device_id", addNewacc?.data?.id);
  //       }
  //       clearTokens();

  //       localStorage.setItem("token", updatedData?.data?.token);
  //       localStorage.setItem("refresh_token", updatedData?.data?.refresh_token);
  //       localStorage.setItem("user_email", user.user.email);
  //       localStorage.setItem("org", selectedOrg);
  //       localStorage.setItem("user", JSON.stringify(userDetails));
  //       localStorage.setItem(
  //         "userName",
  //         user?.userdetails?.user_data?.first_name
  //       );
  //       navigate("/");
  //     }
  //   } else {
  //     toast.error(updatedData?.data?.message);
  //   }
  // };

  const handleGoogleLoginSuccess = async (response) => {
    const googleLoginResp = await responseGoogle(response.credential);
    if (googleLoginResp.status === 200) {
      toast.success("Google login successful");
      const decodedToken = jwt_decode(googleLoginResp?.data?.token);
      if (decodedToken !== undefined) {
        const user = decodedToken.data;
        let userDetails = {
          org_uuid: user.org_uuid,
          user_uuid: user.user.user_uuid,
          email: user.user.email,
        };
        setSelectedOrg(googleLoginResp?.data?.org);

        Cookies.set("token", googleLoginResp?.data?.token, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("refresh_token", googleLoginResp?.data?.refresh_token, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("user_email", user.user.email, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("org", selectedOrg, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("user", JSON.stringify(userDetails), {
          domain: URL_POSTFIX,
          expires: 7,
        });
        Cookies.set("org_uuid", userDetails?.org_uuid, {
          domain: URL_POSTFIX,
          expires: 7,
        });
        // navigate("/");
        window.location.href = `https://${selectedOrg}${URL_POSTFIX}`;
      }
    } else {
      toast.error(googleLoginResp?.data?.message);
    }
  };

  const handleGoogleLoginError = (error) => {
    console.error("Google Login Error: ", error);
    toast.error("Google login failed. Please try again.");
  };

  const textFieldStyle = (value) => {
    return {
      // "& .MuiOutlinedInput-root": {
      //   "& fieldset": {
      //     borderColor: value ? "white" : "lightgray",
      //     borderRadius: 2,
      //     backgroundColor: "inherit",
      //   },
      //   "&:hover fieldset": {
      //     borderColor: value ? "white" : "lightgray",
      //     backgroundColor: "inherit",
      //   },
      //   "&.Mui-focused fieldset": {
      //     borderColor: "deepPink",
      //   },
      //   "& input": {
      //     color: "white",
      //     backgroundColor: "inherit",
      //   },
      //   "& .MuiInputBase-input": {
      //     color: "white",
      //     backgroundColor: "inherit",
      //   },
      //   "& input:-webkit-autofill": {
      //     "-webkit-box-shadow": "0 0 0 100px #121212 inset",
      //     "-webkit-text-fill-color": "white",
      //     backgroundColor: "inherit",
      //   },
      // },
      // "& .MuiInputLabel-root": {
      //   color: value ? "deepPink" : "lightgray",
      // },
      // "& .MuiInputLabel-root.Mui-focused": {
      //   color: "deepPink",
      // },
    };
  };

  if (step === 3)
    return (
      <Otp
        email={email}
        selectedOrg={selectedOrg}
        selectedOrgUuid={selectedOrgUuid}
      />
    );

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-dark min-h-screen flex flex-col xs:p-2">
        <div className="sio-col-420 mb-auto">
          <img
            src={signupLogo}
            alt="signup-page-logo"
            className="mt-12 mb-2 xs:h-10"
          />
          <div className="sio-card bg-white-gradient">
            <div className="text-center">
              <h1>Welcome!</h1>
              <p className="text-secondary-light text-xs sm:text-sm">
                Sign into your Strived account
              </p>
            </div>
            <div>
              {step === 1 && (
                <TextField
                  label="Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  fullWidth
                  error={!!emailError}
                  helperText={emailError}
                  sx={textFieldStyle(email)}
                />
              )}
              {step === 2 && (
                <FormControl fullWidth sx={textFieldStyle(selectedOrg)}>
                  <InputLabel>Select Organization</InputLabel>
                  <Select
                    value={selectedOrg}
                    label="Select Organization"
                    onChange={(e) => {
                      setSelectedOrg(e.target.value);
                      const uuid = orgUuidList.find(
                        (org) => org.name === e.target.value
                      );

                      setSelectedOrgUuid(uuid?.org_uuid);
                    }}>
                    {orgList.map((org, index) => (
                      <MenuItem key={index} value={org}>
                        {org}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {/* {step === 3 && (
                // <FormControl
                //   fullWidth
                //   sx={textFieldStyle(otp)}
                //   variant="outlined"
                // >
                //   <InputLabel htmlFor="outlined-adornment-otp">OTP</InputLabel>
                //   <OutlinedInput
                //     onChange={(e) => setOtp(e.target.value)}
                //     id="outlined-adornment-otp"
                //     label="OTP"
                //     autoComplete="off"
                //   />
                // </FormControl>
                
              )} */}
            </div>
            <div>
              {step === 1 && (
                <Button
                  variant="secondary"
                  size="full"
                  onClick={handleEmailContinue}>
                  Continue
                </Button>
              )}
              {step === 2 && (
                <Button
                  variant="secondary"
                  size="full"
                  onClick={handleOrgContinue}>
                  Proceed
                </Button>
              )}
              {/* {step === 3 && (
                <>
                  <Button variant="secondary" size="full" onClick={handleLogin}>
                    Sign In
                  </Button>
                </>
              )} */}
            </div>
            <div className="sio-divider">OR</div>
            <div className="sio-google-btn">
              <GoogleLogin
                theme="outline"
                logo_alignment="center"
                width={352}
                size="large"
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginError}
                context="signin"
                allowed_parent_origin={["https://dev.strived.io"]}
              />
            </div>
          </div>
          <div className="sio-card bg-secondary-gradient xs:-mt-5 sm:-mt-10 xs:pt-10 sm:pt-20 flex flex-row items-center">
            <p className="grow basis-0 text-secondary-light font-medium xs:text-sm">
              If your organization is not registered with us
            </p>
            <Button variant="primary" size="small">
              Contact Us
            </Button>
          </div>
        </div>

        <footer className="flex justify-center p-3">
          <ul className="flex divide-x text-sm divide-light font-medium leading-none flex-wrap justify-center">
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="#">
                {" "}
                Terms of Use
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="#">
                Privacy Policy
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0 text-muted-light xs:border-0 md:border-1">
              ©2024 All Rights Reserved
            </li>
          </ul>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default Login;
